import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import './hsr-guides.scss';
import { Accordion } from 'react-bootstrap';

const HSRGuidesDebuff: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page hsr-guide'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Characters with debuffs</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_debuffs.jpg"
            alt="Early gearing"
          />
        </div>
        <div className="page-details">
          <h1>Characters with debuffs</h1>
          <h2>
            List of all characters that can debuff enemies to make building
            teams easier (especially for Dr. Ratio and Acheron).
          </h2>
          <p>
            Last updated: <strong>12/09/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Debuffs" />
        <p>
          This guide will list all possible debuffs in the game (and also
          effects that aren't debuffs, but may look like it), to make it easier
          for you to build your teams.
        </p>
        <ul>
          <li>
            Overall, if a status effect has a red arrow pointing down, it's a
            debuff,
          </li>
          <li>
            All the debuffs listed below have been restricted to E2 for 5-star
            characters and E6 for 4-star characters,
          </li>
          <li>
            <strong>Break debuff isn't included in the numbers below</strong>{' '}
            and only debuffs applied via skills, traces or Eidolon are,
          </li>
          <li>
            Please keep in mind that Debuffs applied via Technique will only
            take effect at the start of the battle, so you can't fully rely on
            them to reach Dr. Ratio debuff limit.
          </li>
        </ul>
        <div className="detailed-cones moc debuffs-ratio">
          <div className={`single-cone Quantum`}>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <div className="percentage big">
                    <p>7 (+1)</p>
                  </div>
                  <div className="character-debuff">
                    <strong>Silver Wolf</strong>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="information debuffs">
                    <div>
                      <h6 className="debuff">Debuffs - kit</h6>
                      <ol>
                        <li>Weakness Implant (Skill)</li>
                        <li>Weakness DMG RES Shred (Skill)</li>
                        <li>DEF Shred (Ultimate)</li>
                        <li>Bug - ATK (Any skill)</li>
                        <li>Bug - DEF (Any skill)</li>
                        <li>Bug - SPD (Any skill)</li>
                        <li>Effect RES Shred (E2)</li>
                      </ol>
                    </div>
                    <div>
                      <h6 className="debuff">Debuffs - Light Cones</h6>
                      <ul>
                        <li>Those Many Springs (+1)</li>
                        <li>Resolution Shines As Pearls of Sweat (+1)</li>
                        <li>Patience Is All You Need (+1)</li>
                        <li>Incessant Rain (+1)</li>
                      </ul>
                    </div>
                    <div>
                      <h6 className="other">Other effects</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <div className="percentage big">
                    <p>3 (+1)</p>
                  </div>
                  <div className="character-debuff">
                    <strong>Guinaifen</strong>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="information debuffs">
                    <div>
                      <h6 className="debuff">Debuffs - kit</h6>
                      <ol>
                        <li>Burn (Basic via A2 + Skill)</li>
                        <li>Firekiss (Talent + Technique)</li>
                        <li>Effect RES Shred (E1)</li>
                      </ol>
                    </div>
                    <div>
                      <h6 className="debuff">Debuffs - Light Cones</h6>
                      <ul>
                        <li>Those Many Springs (+1)</li>
                        <li>Resolution Shines As Pearls of Sweat (+1)</li>
                        <li>Patience Is All You Need (+1)</li>
                        <li>Incessant Rain (+1)</li>
                      </ul>
                    </div>
                    <div>
                      <h6 className="other">Other effects</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <div className="percentage big">
                    <p>3 (+1)</p>
                  </div>
                  <div className="character-debuff">
                    <strong>Pela</strong>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="information debuffs">
                    <div>
                      <h6 className="debuff">Debuffs - kit</h6>
                      <ol>
                        <li>Exposed - DEF Shred (Ultimate)</li>
                        <li>DEF Shred (Technique)</li>
                        <li>ICE RES Shred (Skill via E4)</li>
                      </ol>
                    </div>
                    <div>
                      <h6 className="debuff">Debuffs - Light Cones</h6>
                      <ul>
                        <li>Those Many Springs (+1)</li>
                        <li>Resolution Shines As Pearls of Sweat (+1)</li>
                        <li>Patience Is All You Need (+1)</li>
                        <li>Incessant Rain (+1)</li>
                      </ul>
                    </div>
                    <div>
                      <h6 className="other">Other effects</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="26">
                <Accordion.Header>
                  <div className="percentage big">
                    <p>3 (+1)</p>
                  </div>
                  <div className="character-debuff">
                    <strong>Black Swan</strong>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="information debuffs">
                    <div>
                      <h6 className="debuff">Debuffs - kit</h6>
                      <ol>
                        <li>Arcana (Basic and Skill)</li>
                        <li>DEF Shred (Skill)</li>
                        <li>DMG Taken (Ultimate)</li>
                      </ol>
                    </div>
                    <div>
                      <h6 className="debuff">Debuffs - Light Cones</h6>
                      <ul>
                        <li>Those Many Springs (+1)</li>
                        <li>Resolution Shines As Pearls of Sweat (+1)</li>
                        <li>Patience Is All You Need (+1)</li>
                        <li>Incessant Rain (+1)</li>
                      </ul>
                    </div>
                    <div>
                      <h6 className="other">Other effects</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <div className="percentage big">
                    <p>3 (+1)</p>
                  </div>
                  <div className="character-debuff">
                    <strong>Welt</strong>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="information debuffs">
                    <div>
                      <h6 className="debuff">Debuffs - kit</h6>
                      <ol>
                        <li>Slow (Skill)</li>
                        <li>Imprison (Ultimate + Technique)</li>
                        <li>Vulnerability (Ultimate via A2)</li>
                      </ol>
                    </div>
                    <div>
                      <h6 className="debuff">Debuffs - Light Cones</h6>
                      <ul>
                        <li>Those Many Springs (+1)</li>
                        <li>Resolution Shines As Pearls of Sweat (+1)</li>
                        <li>Patience Is All You Need (+1)</li>
                        <li>Incessant Rain (+1)</li>
                      </ul>
                    </div>
                    <div>
                      <h6 className="other">Other effects</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="35">
                <Accordion.Header>
                  <div className="percentage big">
                    <p>2 (+1)</p>
                  </div>
                  <div className="character-debuff">
                    <strong>Jiaoqiu</strong>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="information debuffs">
                    <div>
                      <h6 className="debuff">Debuffs - kit</h6>
                      <ol>
                        <li>Ashen Roast (Talent + Technique)</li>
                        <li>Ultimate DMG Vulnerability (Ultimate)</li>
                      </ol>
                    </div>
                    <div>
                      <h6 className="debuff">Debuffs - Light Cones</h6>
                      <ul>
                        <li>Those Many Springs (+1)</li>
                        <li>Resolution Shines As Pearls of Sweat (+1)</li>
                        <li>Patience Is All You Need (+1)</li>
                        <li>Incessant Rain (+1)</li>
                      </ul>
                    </div>
                    <div>
                      <h6 className="other">Other effects</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  <div className="percentage big">
                    <p>2 (+1)</p>
                  </div>
                  <div className="character-debuff">
                    <strong>Topaz</strong>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="information debuffs">
                    <div>
                      <h6 className="debuff">Debuffs - kit</h6>
                      <ol>
                        <li>Proof of Debt (Skill)</li>
                        <li>Debtor (E1)</li>
                      </ol>
                    </div>
                    <div>
                      <h6 className="debuff">Debuffs - Light Cones</h6>
                      <ul>
                        <li>Worrisome, Blissful (+1)</li>
                      </ul>
                    </div>
                    <div>
                      <h6 className="other">Other effects</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  <div className="percentage big">
                    <p>2 (+1)</p>
                  </div>
                  <div className="character-debuff">
                    <strong>Luka</strong>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="information debuffs">
                    <div>
                      <h6 className="debuff">Debuffs - kit</h6>
                      <ol>
                        <li>Bleed (Skill + Technique)</li>
                        <li>DMG received (Ultimate)</li>
                      </ol>
                    </div>
                    <div>
                      <h6 className="debuff">Debuffs - Light Cones</h6>
                      <ul>
                        <li>Those Many Springs (+1)</li>
                        <li>Resolution Shines As Pearls of Sweat (+1)</li>
                        <li>Patience Is All You Need (+1)</li>
                        <li>Incessant Rain (+1)</li>
                      </ul>
                    </div>
                    <div>
                      <h6 className="other">Other effects</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  <div className="percentage big">
                    <p>2 (+1)</p>
                  </div>
                  <div className="character-debuff">
                    <strong>Gepard</strong>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="information debuffs">
                    <div>
                      <h6 className="debuff">Debuffs - kit</h6>
                      <ol>
                        <li>Freeze (Skill)</li>
                        <li>SPD Reduction (Skill via E2)</li>
                      </ol>
                    </div>
                    <div>
                      <h6 className="debuff">Debuffs - Light Cones</h6>
                      <ul>
                        <li>Trend of the Universal Market (+1)</li>
                      </ul>
                    </div>
                    <div>
                      <h6 className="other">Other effects</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  <div className="percentage big">
                    <p>2 (+1)</p>
                  </div>
                  <div className="character-debuff">
                    <strong>Sampo</strong>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="information debuffs">
                    <div>
                      <h6 className="debuff">Debuffs - kit</h6>
                      <ol>
                        <li>Wind Shear (Any skill)</li>
                        <li>DoT damage taken (Ultimate)</li>
                      </ol>
                    </div>
                    <div>
                      <h6 className="debuff">Debuffs - Light Cones</h6>
                      <ul>
                        <li>Those Many Springs (+1)</li>
                        <li>Resolution Shines As Pearls of Sweat (+1)</li>
                        <li>Patience Is All You Need (+1)</li>
                        <li>Incessant Rain (+1)</li>
                      </ul>
                    </div>
                    <div>
                      <h6 className="other">Other effects</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="33">
                <Accordion.Header>
                  <div className="percentage big">
                    <p>2 (+1)</p>
                  </div>
                  <div className="character-debuff">
                    <strong>Moze</strong>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="information debuffs">
                    <div>
                      <h6 className="debuff">Debuffs - kit</h6>
                      <ol>
                        <li>Prey (Skill)</li>
                        <li>FUA Damage taken (A6)</li>
                      </ol>
                    </div>
                    <div>
                      <h6 className="debuff">Debuffs - Light Cones</h6>
                      <ul>
                        <li>Worrisome, Blissful (+1)</li>
                      </ul>
                    </div>
                    <div>
                      <h6 className="other">Other effects</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header>
                  <div className="percentage big">
                    <p>2 (+1)</p>
                  </div>
                  <div className="character-debuff">
                    <strong>Kafka</strong>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="information debuffs">
                    <div>
                      <h6 className="debuff">Debuffs - kit</h6>
                      <ol>
                        <li>Shock (Ultimate + Talent + Technique)</li>
                        <li>DoT damage taken (E1)</li>
                      </ol>
                    </div>
                    <div>
                      <h6 className="debuff">Debuffs - Light Cones</h6>
                      <ul>
                        <li>Those Many Springs (+1)</li>
                        <li>Resolution Shines As Pearls of Sweat (+1)</li>
                        <li>Patience Is All You Need (+1)</li>
                        <li>Incessant Rain (+1)</li>
                      </ul>
                    </div>
                    <div>
                      <h6 className="other">Other effects</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="23">
                <Accordion.Header>
                  <div className="percentage big">
                    <p>2 (+1)</p>
                  </div>
                  <div className="character-debuff">
                    <strong>Dr. Ratio</strong>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="information debuffs">
                    <div>
                      <h6 className="debuff">Debuffs - kit</h6>
                      <ol>
                        <li>Effect RES Shred (Skill via A4)</li>
                        <li>SPD Debuff (Technique)</li>
                      </ol>
                    </div>
                    <div>
                      <h6 className="debuff">Debuffs - Light Cones</h6>
                      <ul>
                        <li>Worrisome, Blissful (+1)</li>
                      </ul>
                    </div>
                    <div>
                      <h6 className="other">Other effects</h6>
                      <ol>
                        <li>Wiseman's Folly (Ultimate)</li>
                      </ol>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="29">
                <Accordion.Header>
                  <div className="percentage big">
                    <p>2</p>
                  </div>
                  <div className="character-debuff">
                    <strong>Misha</strong>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="information debuffs">
                    <div>
                      <h6 className="debuff">Debuffs - kit</h6>
                      <ol>
                        <li>Freeze (Ultimate)</li>
                        <li>DEF Shred (E2)</li>
                      </ol>
                    </div>
                    <div>
                      <h6 className="debuff">Debuffs - Light Cones</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                    <div>
                      <h6 className="other">Other effects</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="27">
                <Accordion.Header>
                  <div className="percentage big">
                    <p>2</p>
                  </div>
                  <div className="character-debuff">
                    <strong>Gallagher</strong>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="information debuffs">
                    <div>
                      <h6 className="debuff">Debuffs - kit</h6>
                      <ol>
                        <li>Break DMG Taken (Ultimate)</li>
                        <li>ATK Down (Basic)</li>
                      </ol>
                    </div>
                    <div>
                      <h6 className="debuff">Debuffs - Light Cones</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                    <div>
                      <h6 className="other">Other effects</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="10">
                <Accordion.Header>
                  <div className="percentage big">
                    <p>2</p>
                  </div>
                  <div className="character-debuff">
                    <strong>Himeko</strong>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="information debuffs">
                    <div>
                      <h6 className="debuff">Debuffs - kit</h6>
                      <ol>
                        <li>Burn (Any skill via A2)</li>
                        <li>Fire DMG Taken (Technique)</li>
                      </ol>
                    </div>
                    <div>
                      <h6 className="debuff">Debuffs - Light Cones</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                    <div>
                      <h6 className="other">Other effects</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="30">
                <Accordion.Header>
                  <div className="percentage big">
                    <p>2</p>
                  </div>
                  <div className="character-debuff">
                    <strong>Boothill</strong>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="information debuffs">
                    <div>
                      <h6 className="debuff">Debuffs - kit</h6>
                      <ol>
                        <li>DMG Taken (Skill)</li>
                        <li>Weakness Implant (Ult & Technique)</li>
                      </ol>
                    </div>
                    <div>
                      <h6 className="debuff">Debuffs - Light Cones</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                    <div>
                      <h6 className="other">Other effects</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="9">
                <Accordion.Header>
                  <div className="percentage big">
                    <p>1 (+1)</p>
                  </div>
                  <div className="character-debuff">
                    <strong>Fire Trailblazer</strong>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="information debuffs">
                    <div>
                      <h6 className="debuff">Debuffs - kit</h6>
                      <ol>
                        <li>Taunt (Skill)</li>
                      </ol>
                    </div>
                    <div>
                      <h6 className="debuff">Debuffs - Light Cones</h6>
                      <ul>
                        <li>Trend of the Universal Market (+1)</li>
                      </ul>
                    </div>
                    <div>
                      <h6 className="other">Other effects</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="12">
                <Accordion.Header>
                  <div className="percentage big">
                    <p>1 (+1)</p>
                  </div>
                  <div className="character-debuff">
                    <strong>Yanqing</strong>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="information debuffs">
                    <div>
                      <h6 className="debuff">Debuffs - kit</h6>
                      <ol>
                        <li>Freeze (Talent)</li>
                      </ol>
                    </div>
                    <div>
                      <h6 className="debuff">Debuffs - Light Cones</h6>
                      <ul>
                        <li>Worrisome, Blissful (+1)</li>
                      </ul>
                    </div>
                    <div>
                      <h6 className="other">Other effects</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="34">
                <Accordion.Header>
                  <div className="percentage big">
                    <p>1 (+1)</p>
                  </div>
                  <div className="character-debuff">
                    <strong>Firefly</strong>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="information debuffs">
                    <div>
                      <h6 className="debuff">Debuffs - kit</h6>
                      <ol>
                        <li>Weakness Implant (Skill & Technique)</li>
                      </ol>
                    </div>
                    <div>
                      <h6 className="debuff">Debuffs - Light Cones</h6>
                      <ul>
                        <li>Whereabouts Should Dreams Rest (+1)</li>
                      </ul>
                    </div>
                    <div>
                      <h6 className="other">Other effects</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="25">
                <Accordion.Header>
                  <div className="percentage big">
                    <p>1 (+1)</p>
                  </div>
                  <div className="character-debuff">
                    <strong>Aventurine</strong>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="information debuffs">
                    <div>
                      <h6 className="debuff">Debuffs - kit</h6>
                      <ol>
                        <li>CRIT DMG Taken (Ultimate)</li>
                      </ol>
                    </div>
                    <div>
                      <h6 className="debuff">Debuffs - Light Cones</h6>
                      <ul>
                        <li>Trend of the Universal Market (+1)</li>
                        <li>Inherently Unjust Destiny (+1)</li>
                      </ul>
                    </div>
                    <div>
                      <h6 className="other">Other effects</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <div className="percentage big">
                    <p>1 (+1)</p>
                  </div>
                  <div className="character-debuff">
                    <strong>Acheron</strong>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="information debuffs">
                    <div>
                      <h6 className="debuff">Debuffs - kit</h6>
                      <ol>
                        <li>All-Res Shred (Talent)</li>
                      </ol>
                    </div>
                    <div>
                      <h6 className="debuff">Debuffs - Light Cones</h6>
                      <ul>
                        <li>Those Many Springs (+1)</li>
                        <li>Resolution Shines As Pearls of Sweat (+1)</li>
                        <li>Incessant Rain (+1)</li>
                        <li>Patience is All You Need (+1)</li>
                      </ul>
                    </div>
                    <div>
                      <h6 className="other">Other effects</h6>
                      <ol>
                        <li>Crimson Knot - not a debuff.</li>
                      </ol>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="17">
                <Accordion.Header>
                  <div className="percentage big">
                    <p>1 (+1)</p>
                  </div>
                  <div className="character-debuff">
                    <strong>March 7th</strong>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="information debuffs">
                    <div>
                      <h6 className="debuff">Debuffs - kit</h6>
                      <ol>
                        <li>Freeze (Ultimate + Technique)</li>
                      </ol>
                    </div>
                    <div>
                      <h6 className="debuff">Debuffs - Light Cones</h6>
                      <ul>
                        <li>Trend of the Universal Market (+1)</li>
                        <li>Inherently Unjust Destiny (+1)</li>
                      </ul>
                    </div>
                    <div>
                      <h6 className="other">Other effects</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="31">
                <Accordion.Header>
                  <div className="percentage big">
                    <p>1</p>
                  </div>
                  <div className="character-debuff">
                    <strong>Yunli</strong>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="information debuffs">
                    <div>
                      <h6 className="debuff">Debuffs - kit</h6>
                      <ol>
                        <li>Taunt (Ultimate)</li>
                      </ol>
                    </div>
                    <div>
                      <h6 className="debuff">Debuffs - Light Cones</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                    <div>
                      <h6 className="other">Other effects</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="18">
                <Accordion.Header>
                  <div className="percentage big">
                    <p>1</p>
                  </div>
                  <div className="character-debuff">
                    <strong>Dan Heng</strong>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="information debuffs">
                    <div>
                      <h6 className="debuff">Debuffs - kit</h6>
                      <ol>
                        <li>Slow (Skill)</li>
                      </ol>
                    </div>
                    <div>
                      <h6 className="debuff">Debuffs - Light Cones</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                    <div>
                      <h6 className="other">Other effects</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="15">
                <Accordion.Header>
                  <div className="percentage big">
                    <p>1</p>
                  </div>
                  <div className="character-debuff">
                    <strong>Serval</strong>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="information debuffs">
                    <div>
                      <h6 className="debuff">Debuffs - kit</h6>
                      <ol>
                        <li>Shock (Skill + Ultimate via E4 + Technique)</li>
                      </ol>
                    </div>
                    <div>
                      <h6 className="debuff">Debuffs - Light Cones</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                    <div>
                      <h6 className="other">Other effects</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="13">
                <Accordion.Header>
                  <div className="percentage big">
                    <p>1</p>
                  </div>
                  <div className="character-debuff">
                    <strong>Jingliu</strong>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="information debuffs">
                    <div>
                      <h6 className="debuff">Debuffs - kit</h6>
                      <ol>
                        <li>Freeze (Technique)</li>
                      </ol>
                    </div>
                    <div>
                      <h6 className="debuff">Debuffs - Light Cones</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                    <div>
                      <h6 className="other">Other effects</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="11">
                <Accordion.Header>
                  <div className="percentage big">
                    <p>1</p>
                  </div>
                  <div className="character-debuff">
                    <strong>Ruan Mei</strong>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="information debuffs">
                    <div>
                      <h6 className="debuff">Debuffs - kit</h6>
                      <ol>
                        <li>Thanatoplum Rebloom (Ultimate)</li>
                      </ol>
                    </div>
                    <div>
                      <h6 className="debuff">Debuffs - Light Cones</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                    <div>
                      <h6 className="other">Other effects</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="14">
                <Accordion.Header>
                  <div className="percentage big">
                    <p>1</p>
                  </div>
                  <div className="character-debuff">
                    <strong>Asta</strong>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="information debuffs">
                    <div>
                      <h6 className="debuff">Debuffs - kit</h6>
                      <ol>
                        <li>Burn (Basic via A2)</li>
                      </ol>
                    </div>
                    <div>
                      <h6 className="debuff">Debuffs - Light Cones</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                    <div>
                      <h6 className="other">Other effects</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="16">
                <Accordion.Header>
                  <div className="percentage big">
                    <p>1</p>
                  </div>
                  <div className="character-debuff">
                    <strong>Natasha</strong>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="information debuffs">
                    <div>
                      <h6 className="debuff">Debuffs - kit</h6>
                      <ol>
                        <li>Weaken (Technique)</li>
                      </ol>
                    </div>
                    <div>
                      <h6 className="debuff">Debuffs - Light Cones</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                    <div>
                      <h6 className="other">Other effects</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="19">
                <Accordion.Header>
                  <div className="percentage big">
                    <p>1</p>
                  </div>
                  <div className="character-debuff">
                    <strong>Hook</strong>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="information debuffs">
                    <div>
                      <h6 className="debuff">Debuffs - kit</h6>
                      <ol>
                        <li>Burn (Skill + Technique)</li>
                      </ol>
                    </div>
                    <div>
                      <h6 className="debuff">Debuffs - Light Cones</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                    <div>
                      <h6 className="other">Other effects</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="20">
                <Accordion.Header>
                  <div className="percentage big">
                    <p>1</p>
                  </div>
                  <div className="character-debuff">
                    <strong>Huohuo</strong>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="information debuffs">
                    <div>
                      <h6 className="debuff">Debuffs - kit</h6>
                      <ol>
                        <li>Horror (Technique)</li>
                      </ol>
                    </div>
                    <div>
                      <h6 className="debuff">Debuffs - Light Cones</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                    <div>
                      <h6 className="other">Other effects</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="21">
                <Accordion.Header>
                  <div className="percentage big">
                    <p>0</p>
                  </div>
                  <div className="character-debuff">
                    <strong>Clara</strong>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="information debuffs">
                    <div>
                      <h6 className="debuff">Debuffs - kit</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                    <div>
                      <h6 className="debuff">Debuffs - Light Cones</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                    <div>
                      <h6 className="other">Other effects</h6>
                      <ol>
                        <li>Mark of Counter - not a debuff.</li>
                      </ol>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="22">
                <Accordion.Header>
                  <div className="percentage big">
                    <p>0</p>
                  </div>
                  <div className="character-debuff">
                    <strong>Hanya</strong>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="information debuffs">
                    <div>
                      <h6 className="debuff">Debuffs - kit</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                    <div>
                      <h6 className="debuff">Debuffs - Light Cones</h6>
                      <ul>
                        <li>None.</li>
                      </ul>
                    </div>
                    <div>
                      <h6 className="other">Other effects</h6>
                      <ol>
                        <li>Burden - not a debuff.</li>
                      </ol>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      </div>
    </DashboardLayout>
  );
};

export default HSRGuidesDebuff;

export const Head: React.FC = () => (
  <Seo
    title="Characters with debuffs | Honkai: Star Rail | Prydwen Institute"
    description="List of all characters that can debuff enemies to make building teams easier (especially for Dr. Ratio and Acheron)."
  />
);
